import React from 'react';

export default function render() {
    return (<div>
            <h3 className="terms__title">Website Terms and Conditions</h3>

            <p><strong>Introduction</strong></p>
        <p>Welcome to the <u>Premier</u> website ("<strong>Site</strong>"), provided by Premier, Inc.
            ("<strong>we</strong>", "<strong>our</strong>" or "<strong>us</strong>"). This agreement contains the terms
            and conditions of use upon which you ("<strong>you</strong>", "<strong>your</strong>" or "<strong>the
                user</strong>") may access and use the valuable information and services available through this Site,
            which is controlled, maintained, owned and operated by us and our privacy policy (collectively,
            "<strong>Terms</strong>"). The Terms explain the relationship between us and the user when the user accesses
            and uses the Site, when the user is required to register and provide a password on the Site (when
            applicable), and when the user uses or downloads any online or mobile service or application (including
            related documentation, updates and upgrades) that may be offered through the Site from time to time. Before
            accessing and using the Site, please read these Terms carefully. By using this Site (and/or selecting "I
            Agree" below, if so available), you represent and agree that you are legally competent to do so and further
            agree to be contractually bound by these Terms ("<strong>Acceptance</strong>"). If you are using the Site on
            behalf of your employer, your Acceptance of these Terms is deemed a binding agreement between your employer
            and us and you represent and warrant that you have authority to bind your employer to these Terms.
            Therefore, if you do not agree to any of the Terms, as now existing or later modified, please immediately
            exit this Site and do not further access, view this Site, download Information (defined below) or use any
            features or Information. If you have already downloaded Information, software (if available through this
            Site) or features, please delete or destroy, as applicable, immediately.</p>
        <p><strong>Changes to the Terms; Additional Terms</strong></p>
        <p>We reserve the right to revise these Terms at any time. You agree to review this Site and the Terms
            periodically to ensure that you are aware of any modifications. We do not make changes that have a
            retroactive effect, unless we are legally required to do so. Your continued use of the Site also constitutes
            Acceptance of all amended Terms. The amended Terms supersede all previous versions of the Terms. For this
            reason, we encourage you to review these Terms each time you access or use the Site and to print out a copy
            of these Terms for your records.</p>
        <p>Certain services offered by or through the Site such as shipping, returns or promotions, may be subject to
            additional terms and conditions presented in conjunction with them or otherwise on the Site. In certain
            cases, you must agree to the additional terms before using such services. These Terms and additional terms
            will apply equally. If any additional term is inconsistent with any provision in these Terms, the additional
            term will prevail.</p>
        <p><strong>Ownership; License Grant </strong></p>
        <p>The contents and information accessible on this Site, including, for example, its "look and feel" (e.g.,
            text, graphics, images, logos, and button icons), editorial content, literary content, scientific content,
            notices, software, educational materials, video, audio, photographic or other materials
            ("<strong>Information</strong>") are owned or licensed by us, our business associates, and/or other third
            parties and are protected under both United States and foreign copyright, trademark and other laws.</p>
        <p>We grant to you a limited, personal, nonexclusive, revocable and nontransferable license (without the right
            to sublicense) to access and use the Site subject to these Terms. Permission to reprint or electronically
            reproduce any Information in whole or in part for any other purpose is expressly prohibited, unless prior
            written consent is obtained from us and/or the respective copyright holder(s). You may contact us at the
            address in the "Contacting Us" section at the end of these Terms. You acknowledge that any proprietary
            Information and intellectual property embodied in the Site are not, have not been and will not be licensed
            or otherwise disclosed to you. In addition, you acknowledge that the HTML code that we create to generate
            the pages of the Site is protected by copyright laws and is not licensed to you. All rights not expressly
            granted herein are reserved by us.</p>
        <p>If you believe that any Site Information infringes your copyright in any work, please contact us at the email
            address shown in the "Contacting Us" section at the end of these Terms. Any such notice must include the
            following information: (a) a physical or electronic signature of a person authorized to act on behalf of the
            owner of an exclusive right that has allegedly been infringed; (b) identification of the copyrighted work
            claimed to have been infringed, or if more than one copyrighted work is to be covered by the same
            notification, a representative list of such works; (c) identification of the material that is claimed to be
            infringing or to be the subject of infringing activity and that is to be removed or access to which is to be
            disabled and information reasonably sufficient to permit us to locate the material (please reference the
            URL); (d) your address, telephone number and email address; (e) a statement by you that that you have a good
            faith belief that the use of the material in the manner complained of is not authorized by the copyright
            owner, its agent or the law; and (f) a statement that the information provided by you in the copyright
            notice is accurate, and that you are the copyright owner or authorized to act on behalf of the copyright
            owner. ANY PERSON WHO KNOWINGLY MATERIALLY MISREPRESENTS THAT MATERIAL OR ACTIVITY IS INFRINGING CAN BE
            LIABLE FOR DAMAGES, COSTS AND ATTORNEYS&rsquo; FEES UNDER SECTION 512(f) (LIMITATIONS OF LIABILITY RELATING
            TO MATERIAL ONLINE) OF THE COPYRIGHT ACT.&nbsp;</p>
        <p><strong>Use of the Site; Responsibilities</strong></p>
        <p><u>Use of the Site</u>. The Information on this Site is provided to you solely for your internal business
            use. You may not access or use this Information for commercial, competitive, or any other purposes. You may
            not modify, republish, transmit or distribute any of the Information without the express written consent
            from us. Failure to adhere to these Terms could subject you to serious penalties under federal copyright
            law. In any event, you agree not to modify, amend, reduce the size of or in any way obliterate any warnings,
            liability limitations, disclosures or notices (including any copyright or trademark notice) associated with
            the Information.</p>
        <p>In turn, you grant us and our affiliated companies, successors and assigns, an irrevocable, unrestricted,
            unconditional, worldwide, non-exclusive, royalty-free right and license (including the right to assign,
            transfer and sublicense) to use, store, copy, edit, modify, reproduce, distribute, broadcast, translate,
            made derivative works of, publicly perform, display and otherwise fully exploit in any way Your Content (as
            defined below) on the Site (if applicable) for any purpose, subject to the express terms of this Site. In
            the event that you post Your Content (if such posting is available on this Site) to this Site, you
            understand that while such posted information may no longer be publicly viewable via this Site, it may
            remain stored on servers and other hardware for an indefinite period of time, including, without limitation,
            the devices of other Site users who have accessed or downloaded Your Content.</p>
        <p><u>Your Representations and Responsibilities</u>. You agree to comply with your responsibilities and
            obligations as stated in these Terms. You represent and warrant that you are at least of the age of majority
            (<em>i.e.</em>, the legal age is 18 years of age in most, but not all, states) in the state from which you
            are accessing this Site and that you possess the legal right and ability to enter into these Terms and to
            access and use the Site in the capacity indicated in your registration information (if applicable) in
            accordance with these Terms.</p>
        <p>If you are asked or required to register and establish a user name and password for access to this Site, you
            are solely responsible for maintaining the confidentiality of such user name and password you use to access
            the Site. We may use your user name, password, and account for the purposes of maintaining, modifying,
            remediating, testing, enhancing, and improving this Site and Our Apps, including, without limitation, to
            provide technical support to you, troubleshoot issues on this Site, and improve the user experience
            (collectively, "<strong>Permitted</strong> <strong>Purposes</strong>"), and you hereby consent to our use of
            your user name, password, and account for the Permitted Purposes. Except for the activities that occur as a
            result of our use of your user name, password, and account for the Permitted Purposes, you are fully
            responsible for all activities that occur under your password or account. If you are asked to register,
            certain personal information may be requested from you when asked to create a password-protected account.
            You agree to (i) immediately notify us of any unauthorized use of your password or account or any other
            breach of security, and (ii) ensure that you exit from your account at the end of each session. You agree to
            take reasonable measures to ensure that no unauthorized person or entity shall have access to the Site using
            your user name or password. In the even that you are required to register and create an account
            ("<strong>Account</strong>") on this Site, you agree that you will maintain your Account information to
            ensure it is always current, complete and accurate and that, if you provide untrue, incomplete, misleading
            or inaccurate information, we have the right to terminate your Account and use of the Site at any time
            without notice to you. You agree to allow us to take reasonable measures as necessary to verify the accuracy
            and completeness of the information you submit as part of any registration process related to the creation
            or use of your Account. You further agree that you will use your Account and the Site for your internal
            business purposes only. Except for the activities that occur as a result of our use of your Account for the
            Permitted Purposes, you are responsible for all activities that occur through your Account, whether or not
            authorized by you. Please do not permit anyone else to access your Account. We will not be liable for any
            loss or damage arising from your failure to comply with this provision.</p>
        <p>You are responsible for complying with all laws, rules and regulations that apply to your use of the Site. In
            the event that you may have the opportunity to upload, transmit or post any content to or through the Site,
            (collectively, "<strong>Your Content</strong>"), you agree that you are solely responsible for the accuracy,
            quality and legality of information, data or materials and for ensuring that Your Content complies with
            these Terms. You hereby grant us a worldwide license to use, reproduce, transmit, display and adapt Your
            Content as it deems necessary or desired for us to operate the Site in accordance with these Terms. You
            understand that we may remove Your Content from the Site if we determine that it violates these Terms or for
            any other reason.</p>
        <p>Except as specifically permitted in these Terms or expressly authorized in writing by us, you agree that you
            will not (directly or indirectly):</p>
        <ul>
            <li>distribute, sell, assign, encumber, transfer, rent, lease, sublicense, modify or time-share the Site;
            </li>
            <li>use the Site in any service bureau arrangement;</li>
            <li>post or transmit charity requests; petitions for signatures; franchises, distributorship, sales
                representative agency arrangements, or other business opportunities (including offers of employment or
                contracting arrangements); club memberships; chain letters; or letters relating to pyramid schemes. You
                may not post or transmit any advertising, promotional materials or any other solicitation of other users
                to use goods or services except in those areas (e.g., a classified bulletin board) that are designated
                for such purpose.
            </li>
            <li>use the Site or an Interactive Area (defined below and if applicable on this Site) on the Site for any
                purpose in violation of local, state, federal, or other applicable law.
            </li>
            <li>interfere with other users' use of the Interactive Areas or of the Site generally, including, without
                limitation, disrupting the normal flow of dialogue in any Interactive Area (if available), deleting or
                revising any material posted by another person or entity (if applicable), or taking any action that
                imposes a disproportionate burden on the Site infrastructure or that negatively affects the availability
                of the Site to others.
            </li>
            <li>post or transmit any message which is libelous, defamatory, or which discloses private or personal
                matters concerning any person. You may not post or transmit any message, data, image or program which is
                indecent, obscene or pornographic.
            </li>
            <li>copy or use personal identifying or business contact information about users of the Site without their
                permission.
            </li>
            <li>reproduce, adapt, create derivative works of, translate, localize, port or otherwise modify the Site, in
                whole or part;
            </li>
            <li>incorporate any of our Information into any other work or use our Information in any public or
                commercial manner;
            </li>
            <li>circumvent (or attempt to circumvent) any functionality that controls access to or protects the Site,
                including Accounts;
            </li>
            <li>probe or test the vulnerability of the Site or transmit or otherwise make available any virus, worm,
                Trojan horse, or any other application or code designed to interrupt, contaminate, destroy or limit the
                functionality of any Site;
            </li>
            <li>modify or eliminate any notice about copyright, trademark or other intellectual property right that may
                be part of the Site;
            </li>
            <li>use any means to discover the source code of or trade secrets in any part of the Site;</li>
            <li>attempt to decipher, decompile, disassemble, or reverse-engineer any software making up a part of the
                Site.
            </li>
            <li>engage in any offensive or illegal conduct, including conduct that violates third-party privacy,
                intellectual property, confidential information or other rights;
            </li>
            <li>use or attempt to use any engine, software, tool, agent, or other device or mechanism (including without
                limitation browsers, spiders, robots, avatars or intelligent agents) to navigate or search this Site
                other than the search engines and search agents available from us on this Site and other than general
                publicly available third-party web browsers is prohibited.
            </li>
            <li>assist or knowingly permit any third party to engage in any of the acts proscribed above.</li>
        </ul>
        <p><u>Our Responsibilities</u>. We are responsible for providing the Site in accordance with these Terms and all
            applicable laws, rules and regulations. We are not responsible for providing any equipment and services that
            you may need to access and use the Site. We do not render medical advice. We do not guarantee that the Site
            is accessible on any particular equipment or device or with any particular software or service plan. We
            always reserve the right (but not the obligation) to improve, enhance or modify the Site. We will use
            commercially reasonable efforts to make the Site available to you 24 hours per day, seven (7) days per week,
            excluding scheduled maintenance time, unavailability caused by you or any software, hardware or service not
            provided by us, unscheduled downtime and/or any cause beyond our reasonable control (including without
            limitation nature disasters, wars, terrorist act, civil disturbances, acts of any government or agency
            thereof, strikes or other labor problems, Internet service or other third party service
            providers&rsquo; failures or delays and systemic electrical, telecommunications or other utility outages or
            failures).&nbsp;</p>
        <p><strong>Interactive Areas* (*<em>only if applicable to this Site</em>)</strong></p>
        <p>If available on this Site, we may, from time-to-time, provide areas on the Site to which you or others can
            post messages or transmit communications ("<strong>Interactive Areas</strong>"). As a condition of your use
            of such Interactive Areas, you warrant to us that you will not use the Interactive Areas for any purpose
            that is unlawful or prohibited by these Terms. You are responsible for material that you send to any
            Interactive Area. We reserve the right to deny you access to any part of this service at our sole
            discretion. We also reserve the right to record communications or materials posted in Interactive Areas as
            part of our monitoring activities. We reserve the right (but are not obligated) to monitor, block, edit or
            remove communications or materials that we determine, in our sole discretion, to be (a) abusive, libelous,
            defamatory or obscene, (b) fraudulent, deceptive, or misleading, (c) in violation of a copyright, trademark,
            or other intellectual property right of another, or (d) offensive or otherwise unacceptable to us. We make
            no endorsement, representation or warranty with respect to statements made by you or any user in such
            Interactive Areas.</p>
        <p><strong>Mobile Services Terms* (*<em><u>only if applicable to this Site</u></em>) </strong></p>
        <p>If available on this Site, we may offer mobile applications ("<strong>Our Apps</strong>") from time to time
            for download from a mobile application marketplace, webpages that are optimized for viewing on a mobile
            device and text message (SMS/MMS) alerts or other programs (collectively, the "<strong>Mobile
                Services</strong>"). If we do offer Our Apps through this Site and you do use any of the Mobile
            Services, the additional terms and conditions described in this section ("<strong>Mobile Terms</strong>")
            will also apply to you. Your use of any the Mobile Services confirms your agreement and Acceptance to these
            Mobile Terms, as well as the Terms of this Site. Your use of Our Apps may be subject to separate terms and
            conditions based on the operating system and type of mobile device on which you install them.</p>
        <p>To use the Mobile Services, you must own (or have the right to use) a mobile device with wireless service and
            an Account. You are solely responsible for all message and data fees related to you use of your mobile
            device to access the Mobile Services. All such fees are billed by and payable to your mobile service
            provider or other third-party service provider. Please contact your participating mobile service provider
            for pricing plans, participation status and details. You understand that wireless service through Wi-Fi or a
            participating mobile service provider may not be available in all areas at all times and may be affected by
            product, software, coverage or other service changes made by your mobile service provider or otherwise. We
            are not liable for any delay, interruption or other transmission error related to your mobile device or
            mobile service.</p>
        <p>If you use any of Our Apps and/or Mobile Services and your mobile device is lost, stolen or exchanged for a
            new device, you must immediately report it, and your name and user ID, to the <strong>Premier Solutions
                Center at 877-777-1552</strong> before terminating service with the carrier. You authorize us to disable
            your account on the lost, stolen or exchanged device.</p>
        <p><strong>Third Party Websites, Products and Services, Disclosure of Affiliations, Copyright Notices</strong></p>
        <p>This Site may contain links to third-party websites (<em>e.g.</em>, social media platforms) operated by
            others or information about third-party products and services. We often provide links to and information
            about third-party websites, products and services and do so for your convenience and informational purposes
            only. We do not control such websites and are not responsible for the content and performance of these
            websites or for your transactions with them. Unless expressly stated otherwise, we do not investigate,
            verify, monitor, endorse or recommend any third-party website, product or service or make any representation
            or warranty, either expressed or implied, about any third-party website, product or service or the accuracy,
            reliability or completeness of information about them. Prior to accessing a third-party website or
            purchasing or using any third-party product or service, we recommend that you investigate and evaluate
            whether the third-party website, product or service meets your needs. You acknowledge and agree that we are
            not responsible for any damages or losses caused or alleged to have been caused by your use of any
            third-party sites, or from the products, content, material or information presented by or available through
            those sites. If you find that a third-party product offered for sale by us is not as described or does not
            meet your requirements, your sole remedy is to return it in unused condition in accordance with the
            applicable return policy.</p>
        <p>We may work with a variety of third parties to provide Information that is available on this Site, and to
            provide links to other websites. These third parties may include for example, but are not limited to,
            accrediting bodies, content providers, technology providers, and suppliers of medical products, information
            and services. These entities may supply information for posting on or access through this Site, and, solely
            in the case of accrediting bodies, if applicable, may set standards applicable to certain Information, but
            they do not control the Information or the operation of this Site.</p>
            <p>This Site may include content which is owned by a third-party and the following copyright notices apply
                to such third-party content, as applicable:</p>
            <p><strong>3M<span className="terms-regs">&trade;</span> Software</strong></p>
            <p><i>Reports are produced, in part, by using 3M Company's proprietary computer software created, owned
                and licensed by 3M Company.  All copyrights in and to the 3M<span className="terms-regs">&trade;</span>
                Software are owned by 3M Company or its affiliates.  All rights reserved.</i></p>
            <p><strong>AHA Survey Data</strong></p>
            <p><i>Source: AHA Annual Survey, Health Forum, LLC, a subsidiary of the American Hospital Association.
                AHA Annual Survey <span className="terms-regs">&copy;</span> 2020 Health Forum, LLC.</i></p>
            <p><strong>AHA UB-04 codes</strong></p>
            <p><i>UB-04 Manual and UB-04 Data File. OFFICIAL UB-04 DATA SPECIFICATIONS MANUAL, 2020, is copyrighted by
                American Hospital Association ("AHA"), Chicago, Illinois. No portion of the OFFICIAL UB-04 MANUAL may
                be reproduced, sorted in a retrieval system, or transmitted, in any form or by any means, electronic,
                mechanical, photocopying, recording or otherwise, without prior express, written consent of AHA.</i></p>
            <p><strong>AMA CPT<span className="terms-regs">&reg;</span> codes</strong></p>
            <p><i>CPT copyright 2020 American Medical Association.  All rights reserved.</i></p>
            <p><i>Fee schedules, relative value units, conversion factors and/or related components are not assigned
                by the AMA, are not part of CPT, and the AMA is not recommending their use.  The AMA does not directly
                or indirectly practice medicine or dispense medical services.  The AMA assumes no liability for data
                contained or not contained herein.</i></p>
            <p><i>CPT is a registered trademark of the American Medical Association.</i></p>
            <p><i>U.S. Government End Users. CPT is commercial technical data, which was developed exclusively at
                private expense by the American Medical Association (AMA), 330 North Wabash Avenue,
                Chicago, Illinois 60611.  Use of CPT in connection with this product shall not be construed to grant
                the Federal Government a direct license to use CPT based on FAR 52.227-14 (Data Rights - General)
                and DFARS 252.227-7015 (Technical Data - Commercial Items).</i></p>
            <p><strong>LOINC<span className="terms-regs">&reg;</span> names and codes</strong></p>
            <p><i>This material contains content from LOINC<span className="terms-regs">&reg;</span>
                (http://loinc.org). LOINC is copyright<span className="terms-regs">&copy;</span> 1995-2020, Regenstrief
                Institute, Inc. and the Logical Observation Identifiers Names and Codes (LOINC) Committee and is
                available at no cost under the license at http://loinc.org/license.</i></p>
            <p><strong>Multum consumer drug information</strong></p>
            <p><i>Copyright 2020 Cerner Multum, Inc. All rights reserved.</i></p>
            <p><strong>RxNorm vocabularies</strong></p>
            <p><i>This product uses publicly available data courtesy of the U.S. National Library of Medicine (NLM),
                National Institutes of Health, Department of Health and Human Services; NLM is not responsible for the
                product and does not endorse or recommend this or any other product.</i></p>
            <p><strong>SNOMED Clinical Terms<span className="terms-regs">&reg;</span></strong></p>
            <p><i>This material includes SNOMED Clinical Terms<span className="terms-regs">&reg;</span>
                (SNOMED CT<span className="terms-regs">&reg;</span>) which is used by permission of the International
                Health Terminology Standards Development Organisation (IHTSDO). All rights reserved. SNOMED
                CT<span className="terms-regs">&reg;</span>, was originally created by The College of American
                Pathologists. "SNOMED" and "SNOMED CT" are registered trademarks of the IHTSDO.</i></p>
            <br/>
            <p><strong>Privacy and Security</strong></p>
        <p>Please carefully read our Privacy Policy to learn about the information that we collect through the Site and
            how we may use and process it. We will maintain commercially reasonable administrative, physical and
            technical safeguards intended to protect the security of information that you provide to or through the
            Site. We do not and cannot guarantee that your use of the Site and/or Your Content will be private or
            secure. Information collected by your internet or mobile service provider and other third parties is used,
            stored, transferred and disclosed pursuant to the terms, policies and practices of your internet, mobile or
            other third-party service provider. We are not responsible for or liable to you for any lack of privacy or
            security you may experience. We encourage you to carefully consider disclosure of any information that might
            be accessible to others. You are responsible for using the precautions and security measures best suited for
            your situation and intended use of the Site.</p>
        <p><strong>Disclaimer of Warranties; Limitations of Liability</strong></p>
        <p><strong>YOUR USE OF THE SITE IS AT YOUR OWN RISK. EXCEPT AS EXPRESSLY PROVIDED OTHERWISE HEREIN, THIS SITE
            AND ALL SERVICES, CONTENT AND INFORMATION MADE AVAILABLE ON OR THROUGH THIS SITE ARE PROVIDED ON AN "AS IS",
            "WHERE IS" AND "AS AVAILABLE" BASIS WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED. WE SPECIFICALLY
            DISCLAIM ALL WARRANTIES AND CONDITIONS OF ANY KIND, INCLUDING ALL IMPLIED WARRANTIES AND CONDITIONS OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, FREEDOM FROM DEFECTS, LACK OF OR
            CORRECTION OF ERRORS, LACK OF VIRUSES, LACK OF WORMS OR TROJAN HORSES, RELIABILITY, ACCURACY OR TIMELINESS
            OF INFORMATIONAL CONTENT, UNINTERRUPTED USE AND ALL WARRANTIES IMPLIED FROM ANY COURSE OF DEALING OR USAGE
            OF TRADE. </strong></p>
        <p><strong>YOU AGREE THAT IN NO EVENT WILL WE, NOR OUR OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE (Y)
            FOR DAMAGES OF ANY KIND, INCLUDING DIRECT, INDIRECT, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR
            PUNITIVE DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, LOSS OF USE,
            LOSS OF DATA OR PROFITS, LOSS OF SERVICE OR BUSINESS INTERRUPTION), HOWEVER CAUSED AND UNDER ANY THEORY OF
            LIABILITY, WHETHER ARISING IN ANY WAY IN CONNECTION WITH THESE TERMS AND WHETHER IN CONTRACT, STRICT
            LIABILITY, TORT (INCLUDING NEGLIGENCE OR OTHERWISE) OR OTHERWISE EVEN IF WE HAVE BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGE OR (Z) FOR ANY OTHER CLAIM, DEMAND OR DAMAGES WHATSOEVER RESULTING FROM OR
            ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SITE OR ANY SERVICES OR INFORMATION MADE AVAILABLE ON
            OR THROUGH THE SITE INCLUDING, BUT NOT LIMITED TO, ANY FAILURE OR INTERRUPTION OF THE SYSTEM, OR RESULTING
            FROM THE ACT OR OMISSION OF ANY OTHER PARTY INVOLVED IN MAKING THIS SYSTEM OR THE DATA CONTAINED THEREIN
            AVAILABLE TO YOU, OR FROM ANY OTHER CAUSE RELATING TO YOUR ACCESS TO OR YOUR INABILITY TO ACCESS THE SYSTEM
            OR MATERIALS, WHETHER OR NOT THE CIRCUMSTANCES GIVING RISE TO SUCH CAUSE MAY HAVE BEEN WITHIN OUR CONTROL OR
            OF ANY VENDOR PROVIDING SOFTWARE OR SERVICES SUPPORT, AS MAY BE APPLICABLE. YOU SHALL BE SOLELY RESPONSIBLE
            FOR ANY DAMAGE OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOADING OR USE OF ANY INFORMATION AND FOR ANY
            RESULTS OR LACK OF RESULTS FROM THE USE OF SUCH DATA. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF
            CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES.
            ACCORDINGLY, SOME OR ALL OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. </strong></p>
        <p><strong>WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE SHALL NOT BE LIABLE FOR ANY CLAIMS OR LOSSES IN
            CONNECTION WITH ERRORS, OMISSIONS, OR INACCURACIES OF INFORMATIONAL CONTENT, OR ANY DECISION MADE IN
            RELIANCE ON THE INFORMATION CONTAINED ON OR ACCESSIBLE THROUGH THE SITE. YOUR SOLE REMEDY FOR ANY CLAIMS IN
            CONNECTION WITH THIS SITE IS TO DISCONTINUE USING THIS SITE AND THE RELATED CONTENT AND SERVICES. THE
            FOREGOING LIMITATION SHALL APPLY AND SURVIVE NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY REMEDY.
            IN ANY EVENT, YOU AGREE THAT WE SHALL HAVE NO LIABILITY WHATSOEVER AS A RESULT OF YOUR USE OR PURCHASE (IF
            APPLICABLE) OF ANY THIRD-PARTY PRODUCT OR SERVICE FOR WHICH INFORMATION MAY BE PROVIDED ON THIS
            SITE.</strong></p>
        <p>Notwithstanding the provisions of this section, should we, our officers, directors, employees, affiliates, or
            our licensors or agents be found liable for any damages with respect to any Claims (defined below in the
            "Indemnification" section) alleged or brought, you expressly and irrevocably acknowledge and agree that in
            no event shall any such award of damages exceed the sum of one hundred dollars ($100.00) in United States
            currency. You acknowledge and agree that the above limitations of liability together with the other
            provisions in these Terms that limit liability are essential terms and that we would not be willing to grant
            you the rights set forth in these Terms but for your agreement to the above limitations of liability.</p>
        <p><strong>Indemnification</strong></p>
        <p>You agree to indemnify and defend us and our directors, officers, employees, agents and affiliates from and
            against all losses, liabilities, actual or pending claims, actions, proceedings, suits, damages, expenses,
            costs of defense, including reasonable attorneys&rsquo; fees, brought against us by any third party arising
            from or related to these Terms or your reliance on the Site, use of the Site or access to the Site (or use
            by any other person accessing the Site on your behalf or using your credentials) or any violation of these
            Terms, the rights of a third party or applicable law (including but not limited to infringement of third
            parties' worldwide intellectual property rights or negligent or wrongful conduct) by you or any other person
            accessing the Site using your user name and password (collectively, "<strong>Claims</strong>"). We reserve
            the right, at its own expense, to assume the exclusive defense and control of any matter subject to
            indemnification hereunder. In any event, no settlement that affects the rights or obligations of us may be
            made without our prior written approval.</p>
        <p><strong>Termination; Survival</strong></p>
        <p>These Terms automatically terminate when you fail to comply with any term or condition of them. When these
            Terms terminate, your right to use the Site automatically terminates and you must immediately destroy any
            copies you may have made of our Information. We may modify or terminate your access to the Site at any time
            for any reason or no reason at all, with or without notice to you. Termination will not limit any of our
            other rights or remedies. The provisions of these Terms, which by their nature would continue beyond
            termination or expiration of these Terms, shall survive such termination or expiration.</p>
        <p><strong>Jurisdictional Issues; Taxes</strong></p>
        <p>We make no representation that this Site is appropriate for or available to locations outside the United
            States of America. Use of the Site outside of the United States of America is at your own risk and you are
            responsible for compliance with all applicable laws. We reserve the right to limit the availability of the
            Site to any geographic area. Any software on the Site is further subject to United States export controls.
            If downloadable software is made available through this Site, no software may be downloaded or otherwise
            exported or re-exported: (a) into (or to a national or resident of) Cuba, Iraq, Libya, North Korea, Iran,
            Syria or any other country designated by Sponsor and/or the United States Treasury's Office of Foreign
            Assets Control; or (b) to anyone on the U.S. Treasury Department's list of Specially Designated Nationals or
            the U.S. Commerce Department's Table of Deny Orders. By downloading or using any software from or through
            the Site (if made available), you represent and warrant that you are not located in, under the control of or
            a national or resident of any such country or on any such list.</p>
        <p>You are solely responsible for any and all duties, taxes, levies or fees (including any sales, use or
            withholding taxes) imposed on or in connection with your use of the Site by any taxing authority.</p>
        <p><strong>Notice to California Residents </strong></p>
        <p>Under California Civil Code Section 1789.3, California residents are entitled to the following specific
            consumer rights information:</p>
        <p>The provider of this Site is:</p>
        <p>Premier Healthcare Solutions, Inc. <br/> 13034 Ballantyne Corporate Place<br/> Charlotte, NC 28277</p>
        <p>To file a complaint regarding the Site or to receive further information regarding use of the Site, please
            send a letter to the above address or contact us via e-mail at solutioncenter@premierinc.com (with
            "California Resident Request" as the Subject Line). You may also contact the Division of Consumer Affairs,
            Consumer Information Division in writing at 1625 North Market Blvd., Suite N-112, Sacramento, California
            95814, by telephone at 800.952.5210 / 916-445-1254 or via the Internet at www.dca.ca.gov.</p>
        <p><strong>Miscellaneous </strong></p>
        <p>(a) These Terms inure to the benefit of and will be binding upon you and your successors and assigns,
            respectively.</p>
        <p>(b) These Terms may be assigned by us but you may not assign them without the prior express written consent
            of us.</p>
        <p>(c) If we fail or you fail to perform any obligation under these Terms and the other party does not enforce
            such obligation, the failure to enforce on any occasion will not constitute a waiver of any obligation and
            will not prevent enforcement on any other occasion.</p>
        <p>(d) Nothing contained in these Terms will be deemed to constitute that we or you are the agent or
            representative of the other or as joint venturers or partners.</p>
        <p>(e) If either we or you are prevented from performing or are unable to perform any obligation under these
            Terms due to any cause beyond the reasonable control of the party invoking this provision, the affected
            party's performance will be extended for the period of delay or inability to perform due to such cause.</p>
        <p>(f) Any cause of action or claim you may have with respect to us must be commenced within one (1) year after
            the claim or cause of action arises.</p>
        <p>(g) Headings and captions are for convenience only.</p>
        <p>(h) These Terms and all related documentation will be drafted in English.</p>
        <p><strong>Governing Law and Forum</strong></p>
        <p>These Terms shall be governed by and construed in accordance with the laws of the State of North Carolina
            without regard to its provisions relating to conflicts of law. You agree that any legal action or proceeding
            between us and you for any purpose concerning these Terms or the parties' obligations hereunder shall be
            brought exclusively in a court of competent jurisdiction sitting in Mecklenburg County, North Carolina,
            United States of America. In light of the nature of these Terms, you understand and agree that money damages
            may be insufficient to rectify breach. Consequently, we will be entitled to seek preliminary and equitable
            relief upon a breach of these Terms by you.</p>
        <p><strong>Entire Agreement </strong></p>
        <p>These Terms comprise the full and final understanding between you and us and merges and supersedes any and
            all other agreements, understandings or representations, written or oral, with respect to the subject matter
            hereof. These Terms may not be modified except by a writing (in paper or electronic form) signed by you and
            by an authorized representative of us and referring specifically to these Terms. Neither the course of
            conduct between the parties nor trade practice shall act to modify any provision of these Terms.</p>
        <p><strong>Severability</strong></p>
        <p>The provisions of these Terms are severable, and in the event any provision hereof is determined to be
            invalid or unenforceable for any reason, such invalidity or unenforceability shall not in any way affect the
            validity or enforceability of the remaining provisions.</p>
        <p><strong>Notice to You</strong></p>
        <p>We may deliver notice to you under these Terms by means of electronic mail, a general notice on the Site, or
            by written communication delivered by first-class U.S. mail to your address on record with us as part of
            your registration information.</p>
        <p><strong>Contacting Us</strong></p>
        <p>To contact us with any questions or concerns in connection with these Terms, or to provide any notices under
            these Terms, please contact us at:</p>
        <p>Premier, Inc. <br/> Attn: Web Solutions<br/> 13034 Ballantyne Corporate Place<br/> Charlotte, NC
            28277<br/> (solutioncenter@premierinc.com)</p>
    </div>
    );
}
